.contact {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #282c35;
  color: white;
  padding: 2rem;
  border-radius: 8px;

  @media (max-width: 1400px) {
    height: auto;
  }

  .contact-content {
    display: flex;
    width: 100%;
    flex-direction: column; // Stack the content vertically

    @media (min-width: 768px) {
      flex-direction: row; // Switch to row layout for larger screens
    }

    .social-links {
      flex: 1;
      padding: 1rem;

      @media (min-width: 768px) {
        padding-right: 2rem;
        padding-left: 2rem;
      }

      h2 {
        font-size: 2rem;
        margin-bottom: 1rem;

        @media (max-width: 767px) {
          font-size: 1.5rem; // Adjust font size for smaller screens
        }
      }

      .social-grid {
        display: grid;
        grid-template-columns: 2rem 6rem auto;
        padding-bottom: 2rem;

        .grid-item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding-bottom: 1rem;

          &:nth-child(2),
          &:nth-child(5),
          &:nth-child(8) {
            .label {
              font-size: 1rem;
              padding-left: 1rem;

              @media (max-width: 767px) {
                padding-left: 0.5rem; // Adjust label padding for smaller screens
              }
            }
          }

          svg {
            font-size: 2rem;
          }

          a {
            color: #61dafb;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .download-resume {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;

      button {
        padding: 0.7rem;
        font-size: 1rem;
        background-color: #61dafb;
        color: #333;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #45a9d3;
        }
      }
    }

    .contact-form {
      flex: 1;
      padding: 1rem;

      h2 {
        font-size: 2rem;
        margin-bottom: 1rem;

        @media (max-width: 767px) {
          font-size: 1.5rem; // Adjust font size for smaller screens
        }
      }

      p {
        font-size: 1.2rem;
        line-height: 1.5;
      }

      form {
        display: flex;
        flex-direction: column;
        max-width: 400px;
        margin-top: 1rem;

        .form-group {
          margin-bottom: 1rem;

          label {
            display: block;
            margin-bottom: 0.5rem;
            font-size: 1rem;
            color: #61dafb;
          }

          input,
          textarea {
            width: 100%;
            padding: 0.5rem;
            font-size: 1rem;
            border: 1px solid #ccc;
            border-radius: 4px;
            transition: border-color 0.3s ease;

            &:focus {
              outline: none;
              border-color: #61dafb;
            }
          }
        }

        button {
          width: 100%;
          margin-top: 1rem;
          padding: 0.7rem;
          font-size: 1rem;
          background-color: #61dafb;
          color: #333;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #45a9d3;
          }
        }
      }
    }
  }

  .notification {
    background-color: #4caf50;
    color: white;
    text-align: center;
    padding: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    border-radius: 4px;
    font-size: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: -100px;
    right: -100px;
    animation: slideIn 0.5s ease-out forwards, slideOut 0.5s ease-in reverse forwards;

    @keyframes slideIn {
      to {
        bottom: 1rem;
        right: 1rem;
      }
    }

    @keyframes slideOut {
      to {
        bottom: -100px;
        right: -100px;
      }
    }
  }
}