.projects {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #282c35;
  color: white;
  padding: 2rem;
  border-radius: 8px;

  @media (max-width: 768px) {
    height: auto;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 1.5rem; // Adjust font size for smaller screens
    }
  }

  .projects-container {
    display: flex;
    gap: 2rem;

    @media (max-width: 768px) {
      flex-direction: column; // Stack project grid and list vertically
      gap: 1rem; // Reduce gap between grid and list
    }

    .project-grid {
      flex: 2;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;

      @media (max-width: 768px) {
        grid-template-columns: 1fr; // Switch to a single column layout
      }

      .project {
        background-color: #394049;
        border-radius: 8px;
        padding: 1.5rem;
        text-align: center;
        cursor: pointer;

        h3 {
          font-size: 1.5rem;
          margin-bottom: 0.5rem;

          @media (max-width: 768px) {
            font-size: 1.2rem; // Adjust font size for smaller screens
          }
        }

        p {
          font-size: 1rem;
          line-height: 1.5;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 3em;
        }

        a {
          color: #61dafb;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .project-list {
      flex: 1;
      max-height: 450px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        max-height: none; // Remove fixed height for smaller screens
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #61dafb;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: #282c35;
      }

      .list-item {
        margin-bottom: 1rem;

        h4 {
          font-size: 1.2rem;
          margin-bottom: 0.5rem;

          @media (max-width: 768px) {
            font-size: 1rem; // Adjust font size for smaller screens
          }
        }

        p {
          font-size: 1rem;
          line-height: 1.5;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 3em;
        }

        a {
          color: #61dafb;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    background-color: #394049;
    border-radius: 8px;
    padding: 2rem;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    color: white;

    @media (max-width: 768px) {
      padding: 1rem; // Reduce padding for smaller screens
    }

    h3 {
      font-size: 1.8rem;
      margin-bottom: 1rem;

      @media (max-width: 768px) {
        font-size: 1.5rem; // Adjust font size for smaller screens
      }
    }

    p {
      font-size: 1.2rem;
      line-height: 1.5;

      @media (max-width: 768px) {
        font-size: 1rem; // Adjust font size for smaller screens
      }
    }

    a {
      color: #61dafb;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}