$primary-color: #1a1a1a;
$secondary-color: #282c35;

.portfolio {
  .main-content {
    @media (max-width: 767px) {
      padding-top: 100px; /* Adjust this value as needed */
    }

    @media (min-width: 767px) and (max-width: 1400px) {
      padding-top: 80px; /* Adjust this value as needed */
    }

  }
}