.chat-bubble {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #1a1e25;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    color: #fff;
    font-size: 24px;
  }

  .greeting-message {
    position: absolute;
    top: -30px;
    right: 10px;
    background-color: #1a1e25;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    white-space: nowrap; // Ensure the greeting message stays on one line

    .close-button {
      cursor: pointer;
      color: #fff;
      margin-left: 8px;
    }
  }
}