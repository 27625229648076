.about {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c35;
  color: white;
  padding: 2rem;
  border-radius: 8px;

  .about-content {
    display: flex;
    max-width: 1200px;
    width: 100%;

    @media (max-width: 767px) {
      flex-direction: column; // Stack columns vertically on mobile
    }

    .left-column,
    .right-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .left-column {
      flex: 1;
      padding: 0 1rem;

      img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin-bottom: 1rem;

        @media (max-width: 767px) {
          width: 150px; // Adjust image size for mobile
          height: 150px;
        }
      }

      p {
        text-align: center;
        font-size: 1.2rem;
        line-height: 1.5;
      }
    }

    .right-column {
      flex: 1;
      display: flex;
      flex-direction: row; // Keep the row layout for all screen sizes
      justify-content: center;
      align-items: center;

      .column {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 0 1rem;

        .technology {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          margin-bottom: 0.5rem;
          border: 2px solid #61dafb;
          border-radius: 8px;
          padding: 1rem;

          @media (max-width: 767px) {
            padding: 0.5rem;
            height: 50px;
            width: 90px;
          }

          img {
            width: 30px;
            margin-bottom: 0.2rem;

            @media (max-width: 767px) {
              width: 20px;
            }
          }

          span {
            font-size: 1.2rem;
            font-weight: bold;
            color: #61dafb;

            @media (max-width: 767px) {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}