
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #1a1a1a;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, padding 0.3s ease, height 0.3s ease;


  &.scrolled {
    padding: 15px;
    height: 40px;

    h1.header-title,
    nav ul {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    h1.header-title {
      transform: translateX(-40%);
    }

    nav {
      ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        transform: translate(38%, -230%);
      }
    }
  }

  h1.header-title {
    font-size: 2.5em;
    margin: 0;
    letter-spacing: 2px;
    cursor: pointer;
    transition: transform 0.5s ease;

    @media (max-width: 768px) {
      font-size: 2em;
    }
  }

  nav {
    ul {
      list-style: none;
      padding: 0;
      margin: 20px 0;
      display: flex;
      justify-content: center;
      transition: transform 0.5s ease;

      @media (max-width: 768px) {
        align-items: center;
        margin: 10px 0;
      }
    }

    li {
      margin: 0 15px;
      cursor: pointer;
      font-size: 1.2em;

      @media (max-width: 768px) {
        margin: 0 5px;
        font-size: 1em;
      }
    }
  }
}