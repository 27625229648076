
.chat-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 400px;
  background-color: #394550;
  color: white;
  border: 1px solid #333;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.chat-header {
  background-color: #1a1e25;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
}

.chat-body {
  padding: 10px;
  max-height: 500px;
  overflow-y: auto;

  .message {
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;

    &.support {
      background-color: #007bff;
    }

    &.user {
      background-color: #28a745;
      justify-content: flex-end;
    }

    span {
      display: block;
    }

    .icon {
      margin-right: 8px;
      background-color: #1a1e25;
      border-radius: 50%;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: #fff; // Icon color
      }
    }
  }
}

.message-input {
  padding: 10px;
  border-top: 1px solid #333;

  input {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 4px;
    margin-top: 5px;
    box-sizing: border-box;
  }
}